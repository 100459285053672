<template>
    <div class="console-container">
        <div class="top">
            <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(item, index) in imgs" :key="index">
                    <img :src="item.url"/>
                </van-swipe-item>
            </van-swipe> -->
            <div class="top-content">
                <p>
                    <span class="location"></span>
                    <span>罗江区山智农农业产业园</span>
                </p>
                <p>
                    <span class="num">12°</span>
                    <span>晴</span>
                </p>
                <p>02月12日  空气质量:轻度污染 湿度:60%</p>
            </div>
        </div>    
        <!-- <div class="middle" v-if="!isAdmin">
            <van-notice-bar left-icon="volume-o" :scrollable="false">
                <van-swipe
                    vertical
                    class="notice-swipe"
                    :autoplay="3000"
                    :show-indicators="false"
                >
                    <van-swipe-item v-for="(item, index) in list" :key="index" @click="clickJump(item)">
                       {{item.title}}
                    </van-swipe-item>
                </van-swipe>
            </van-notice-bar>
        </div> -->
        <div class="bottom" v-if="isAdmin">
            <div class="bottom-item">
                <p class="title">园区</p>
                <div class="bottom-content">
                    <van-row>
                        <van-col span="6">
                            <router-link :to="{path: '/home'}">
                                <div class="item">
                                    <img src="../../assets/images/console/news.png"/>
                                    <p>园区信息</p>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col span="6">
                            <router-link :to="{path: '/addressBook'}">
                                <div class="item">
                                    <img src="../../assets/images/console/addressBook.png"/>
                                    <p>通讯录</p>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col span="6">
                            <router-link :to="{path: '/plotManagement'}">
                                <div class="item">
                                    <img src="../../assets/images/console/plot.png"/>
                                    <p>地块管理</p>
                                </div>
                            </router-link>
                        </van-col>
                        <!-- <van-col span="6">
                            <router-link :to="{path: '/agriculturalRecords'}">
                                <div class="item">
                                    <img src="../../assets/images/console/order.png"/>
                                    <p>农事记录</p>
                                </div>
                            </router-link>
                        </van-col> -->
                        <van-col span="6">
                            <div class="item" @click="dockTip">
                                <img src="../../assets/images/console/machinery.png"/>
                                <p>农机管理</p>
                            </div>
                        </van-col>
                    </van-row>
                    <!-- <van-row>
                        <van-col span="6">
                            <div class="item" @click="dockTip">
                                <img src="../../assets/images/console/machinery.png"/>
                                <p>农机管理</p>
                            </div>
                        </van-col>
                    </van-row> -->
                </div>
            </div>
            <div class="bottom-item">
                <p class="title">通知管理</p>
                <div class="bottom-content">
                    <van-row>
                        <van-col span="6">
                            <router-link :to="{path: '/pressReleases'}">
                                <div class="item">
                                    <img src="../../assets/images/console/icon_home_news.png"/>
                                    <p>新闻发布</p>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col span="6">
                            <router-link :to="{path: '/notificationIssuance'}">
                                <div class="item">
                                    <img src="../../assets/images/console/icon_home_notice.png"/>
                                    <p>通知下发</p>
                                </div>
                            </router-link>
                        </van-col>
                        <!-- <van-col span="6">
                            <router-link :to="{path: '/scanQRCode'}">
                                <div class="item">
                                    <img src="../../assets/images/console/icon_home_suyuan.png"/>
                                    <p>质量溯源</p>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col span="6">
                            <router-link :to="{path: '/viewPests'}">
                                <div class="item">
                                    <img src="../../assets/images/console/icon_home_diseases.png"/>
                                    <p>病虫害识别</p>
                                </div>
                            </router-link>
                        </van-col> -->
                    </van-row>
                </div>
            </div>
        </div>
        <div class="bottom" v-else-if="isTourist">
            <div class="bottom-item">
                <p class="title">园区</p>
                <div class="bottom-content">
                    <van-row>
                        <van-col span="6">
                            <router-link :to="{path: '/home'}">
                                <div class="item">
                                    <img src="../../assets/images/console/news.png"/>
                                    <p>园区信息</p>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col span="6">
                            <!-- <router-link :to="{path: '/login'}"> -->
                                <div class="item" @click="judgeLogin">
                                    <img src="../../assets/images/console/addressBook.png"/>
                                    <p>通讯录</p>
                                </div>
                            <!-- </router-link> -->
                        </van-col>
                        <van-col span="6">
                            <!-- <router-link :to="{path: '/login'}"> -->
                                <div class="item" @click="judgeLogin">
                                    <img src="../../assets/images/console/plot.png"/>
                                    <p>地块管理</p>
                                </div>
                            <!-- </router-link> -->
                        </van-col>
                        <van-col span="6">
                            <!-- <router-link :to="{path: '/login'}"> -->
                                <div class="item" @click="judgeLogin">
                                    <img src="../../assets/images/console/order.png"/>
                                    <p>农事记录</p>
                                </div>
                            <!-- </router-link> -->
                        </van-col>
                    </van-row>
                </div>
            </div>
            <div class="bottom-item">
                <p class="title">功能</p>
                <div class="bottom-content">
                    <van-row>
                        <van-col span="6">
                            <!-- <router-link :to="{path: '/login'}"> -->
                                <div class="item" @click="judgeLogin">
                                    <img src="../../assets/images/console/icon_home_news.png"/>
                                    <p>新闻发布</p>
                                </div>
                            <!-- </router-link> -->
                        </van-col>
                        <van-col span="6">
                            <!-- <router-link :to="{path: '/login'}"> -->
                                <div class="item" @click="judgeLogin">
                                    <img src="../../assets/images/console/icon_home_notice.png"/>
                                    <p>通知下发</p>
                                </div>
                            <!-- </router-link> -->
                        </van-col>
                        <van-col span="6">
                            <router-link :to="{path: '/scanQRCode'}">
                                <div class="item">
                                    <img src="../../assets/images/console/icon_home_suyuan.png"/>
                                    <p>质量溯源</p>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col span="6">
                            <router-link :to="{path: '/viewPests'}">
                                <div class="item">
                                    <img src="../../assets/images/console/icon_home_diseases.png"/>
                                    <p>病虫害识别</p>
                                </div>
                            </router-link>
                        </van-col>
                    </van-row>
                </div>
            </div>
        </div>
        
        <div class="bottom" v-else>
            <div class="bottom-item" >
                <div class="notice">
                    <van-notice-bar :scrollable="false">
                        <van-swipe
                            vertical
                            class="notice-swipe"
                            :autoplay="3000"
                            :show-indicators="false"
                        >
                            <van-swipe-item v-for="(item, index) in noticeArr" :key="index">
                                <span class="icon-notice"></span>
                                <p>{{item.title}}</p>
                                <div class="btn-see" @click="clickJump">点击查看</div> 
                            </van-swipe-item>
                        </van-swipe>
                    </van-notice-bar>
                    <!-- <span class="icon-notice"></span>
                    <p>这是通知的相关内容可以的字符</p>
                    <div class="btn-see" @click="clickJump">点击查看</div> -->
                </div>
            </div>
            <div class="bottom-item" >
                <p class="title">园区</p>
                <div class="bottom-content">
                    <van-row>
                        <van-col span="6">
                            <router-link :to="{path: '/home'}">
                                <div class="item">
                                    <img src="../../assets/images/console/news.png"/>
                                    <p>园区信息</p>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col span="6">
                            <router-link :to="{path: '/plotManagement'}">
                                <div class="item">
                                    <img src="../../assets/images/console/plot.png"/>
                                    <p>地块管理</p>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col span="6">
                            <div class="item" @click="dockTip">
                                <img src="../../assets/images/console/machinery.png"/>
                                <p>我的农机</p>
                            </div>
                        </van-col>
                        <van-col span="6">
                            <router-link :to="{path: '/workOrder'}">
                                <div class="item">
                                    <img src="../../assets/images/console/order.png"/>
                                    <p>生产管理</p>
                                </div>
                            </router-link>
                        </van-col>
                    </van-row>
                </div>
            </div>
            <div class="bottom-item" >
                <p class="title">小工具</p>
                <div class="bottom-content">
                    <van-row>
                        <!-- <van-col span="6">
                            <router-link :to="{path: '/plotManagement'}">
                                <div class="item">
                                    <img src="../../assets/images/console/icon_home_suyuan.png"/>
                                    <p>质量溯源</p>
                                </div>
                            </router-link>
                        </van-col> -->
                        <van-col span="6">
                            <router-link :to="{path: '/scanQRCode'}">
                                <div class="item">
                                    <img src="../../assets/images/console/icon_home_suyuan.png"/>
                                    <p>质量溯源</p>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col span="6">
                            <router-link :to="{path: '/viewPests'}">
                                <div class="item">
                                    <img src="../../assets/images/console/icon_home_diseases.png"/>
                                    <p>病虫害识别</p>
                                </div>
                            </router-link>
                        </van-col>
                    </van-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getCurrentUser
} from '@/api/workOrder';
import {getUserType, getToken} from '@/utils/cookie'
import {listPageNewsNotice, addNewsNotice, modifyNewsNotice, deleteNewsNotice} from '@/api/notificationIssuance';
export default {
    data() {
        return {
            isAdmin: true,//判断是否是管理员
            isTourist: false,//判断是否是游客
            imgs: [
                {url: require('../../assets/images/home/u102.png')},
                {url: require('../../assets/images/home/u102.png')},
                {url: require('../../assets/images/home/u102.png')},
            ],
            list: [
                {title: '11', content: '案件发货的撒赴哈佛哈三季度粉红色的不是那八十多付完款'},
                {title: '12', content: '那么你就按双方就会死废物附加费很难受的骄傲吹风机暗杀'},
                {title: '13', content: '贷款将罚款哈弗就和你说接电话发一哈都是部分嘎'},
            ],
            noticeArr: [
                {title: '测试'},
                {title: '测试1'},
                {title: '测试2'},
                {title: '测试3'},
            ]
        }
    },
    mounted() {
        // this.getCurrentUser();
        if (getUserType() == 1) {
            this.isAdmin = true;
            this.isTourist = false;
        } else if (getUserType() == 2) {
            this.isAdmin = false;
            this.isTourist = false;
        } else {
            this.isTourist = true;
            this.isAdmin = false;
        }
        if (getToken()) {
            this.listPageNewsNotice();
        }
    },
    methods: {
        /** 获取当前用户 */
        /* getCurrentUser() {
            getCurrentUser().then(res => {
                if (res.code == 200) {
                    this.isAdmin = res.data.type == 1 ? true : false;
                }
            })
        }, */
        /** 获取通知 */
        listPageNewsNotice() {
            let search =  {
                keyword: '',
                pageIndex: 1,
                pageSize: 200,
                type: 0
            };
            listPageNewsNotice(search).then(res=> {
                if (res.code == 200) {
                    if (res.data.length > 0) {
                        this.noticeArr = res.data
                    } else {
                       if (this.isAdmin) {
                           this.noticeArr = [{title: '暂无通知'}]
                       } else {
                           this.noticeArr = [{title: '农事安排'}]
                       }
                    }
                }
            })
        },
        /** 点击通知跳转 */
        clickJump(obj) {
            this.$router.push({path: '/notificationIssuance'});
        },
        /** 待对接提示 */
        dockTip() {
            // this.$dialog.confirm({
            //     title: '提示',
            //     message: '待对接....',
            // }).then(() => {
               
            // }).catch(() => {
                
            // });
            if (this.isAdmin) {
                this.$router.push({name: 'machineryManagement'});
            } else {
                this.$router.push({name: 'myMachinery'});
            }
        },
        judgeLogin() {
            this.$dialog.confirm({
                title: '提示',
                message: '当前功能需要登录才能查看',
                confirmButtonText: '去登录',
                cancelButtonText: '再看看'
            }).then(() => {
               this.$router.push({path: '/login'})
            }).catch(() => {
                
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .console-container {
        height: 100%;
        background-color: #F1F2F8;
        .top {
            width: 100%;
            height: 232px;
            background: url(../../assets/images/console/img_home_sunny.png);
            background-size: contain;
            .top-content {
                padding-top: 60px;
                padding-left: 16px;
                p {
                    margin-top: 10px;
                    color: #fff;
                }
                p:nth-of-type(1) {
                    font-size: 14px;
                    .location {
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        margin-right: 9px;
                        line-height: 20px;
                        vertical-align: middle;
                        background: url(../../assets/images/console/icon_home_banner_ositioning.png);
                        background-size: contain;
                    }
                }
                p:nth-of-type(2) {
                    font-size: 14px;
                    .num {
                        font-size: 40px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                    } 
                }
                p:nth-of-type(3) {
                    font-size: 12px;
                }
            }
            img {
                width: 346px;
                height: 196px;
                border-radius: 8px;
            }
            .content {
                display: inline-block;
                height: 40px;
            }
        }
        .middle {
            width: 346px;
            height: 40px;
            margin: 0 auto;
            margin-top: 10px;
            border-radius: 8px;
            .van-notice-bar {
                border-radius: 8px;
            }
            
        }
        .bottom {
            position: absolute;
            top: 212px;
            left: 14px;
            width: 347px;
            .bottom-item {
                width: 347px;
                margin: 0 auto;
                margin-bottom: 10px;
                border-radius: 4px;
                background-color: #fff;
                .notice {
                    display: flex;
                    align-items: center;
                    height: 50px;
                    // justify-content: space-around;
                    // padding: 14px 16px;
                    .icon-notice {
                        /* position: absolute;
                        top: 12px;
                        left: 0; */
                        display: inline-block;
                        width: 22px;
                        height: 22px;
                        margin-right: 12px;
                        background: url(../../assets/images/console/home_notice.svg) no-repeat;
                    }
                    p {
                        width: 210px;
                        margin-right: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        color: #727272;
                    }
                    .btn-see {
                        font-size: 14px;
                        color: #369AF7;
                    }
                }
                .title {
                    margin: 0;
                    padding: 12px 16px;
                    font-size: 16px;
                    color: #4F4F4F;
                }
                .bottom-content { 
                    .item {
                        margin-top: 8px;
                        margin-bottom: 22px;
                        text-align: center;
                        img {
                            width: 45px;
                            height: 45px;
                        }
                        p {
                            margin: 0;
                            padding-left: 0;
                            font-size: 12px;
                            text-align: center;
                            color: #727272;
                        }
                    }
                }
            }
           /*  .bottom-content {
                margin-top: 10px;
                .item {
                    width: 80%;
                    height: 70px;
                    margin: 0 auto;
                    margin-top: 15px;
                    border-radius: 8px;
                    color: #595959;
                    text-align: center;
                    background-color: #fff;
                    img {
                        width: 45px;
                        height: 45px;
                        margin: 0 auto;
                    }
                    p {
                        margin: 0;
                        padding-left: 0;
                        font-size: 12px;
                        text-align: center;
                        color: #727272;
                    }
                }
            } */
            
        }
    }
</style>
<style lang="scss">
    .console-container {
        
        .van-notice-bar {
            width: 100%;
            color: #727272;
            background: transparent;
        }
        .notice-swipe {
            height: 40px;
            line-height: 40px;
            
        }
        .van-swipe-item {
            display: flex;
            align-items: center;
            width: 300px;
        }
    }
    
</style>